<template>
  <div>
    <div>
      <Dialog :visible.sync="visible" title="批量导入" size="small" :showFooter="false">
        <LineWrapper :cssStyle="{ fontSize:'13rem' }" labelWidth="130rem">
          <LineItem label="请下载导入数据模板">
            <el-button size="small" icon="el-icon-download" @click="handleDownFile(1)">下载转入模板</el-button>
            <el-button size="small" icon="el-icon-download" @click="handleDownFile(2)">下载转出模板</el-button>
          </LineItem>
          <LineItem label="请上传导入文件">
            <el-button type="primary" size="small" icon="el-icon-upload2" @click="handleSelectFile(1)">上传转入文件</el-button>
            <el-button type="primary" size="small" icon="el-icon-upload2" @click="handleSelectFile(2)">上传转出文件</el-button>
          </LineItem>
        </LineWrapper>
      </Dialog>
    </div>
    <list-template
      isDownload
      :search-config="searchConfig"
      @onSearch="onSearch"
      @onReset="onReset"
      :table-config="tableConfig"
      :table-data="tableData"
      :total="total"
      :loading="loading"
      :current-page="page"
      @onChangePage="handleCurrentChange"
      @onHandle="handleClick"
      @onChange="onChange"
      @onExportData="handDownload"
      @onBatchImport="handleUpload"
      @onAdd="handleAdd"
    >
    </list-template>
  </div>
</template>

<script>
import Dialog from "@/components/common/Dialog";
import LineItem from "@/components/common/LineItem";
import LineWrapper from "@/components/common/LineWrapper";
import {tableListMixin} from '@/utils/mixins'
import {as2_search_class, fileDownload, get_grade, get_major, get_school2} from "@/api";
import {handleChooseFile} from "@/utils";

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  components: { Dialog, LineItem, LineWrapper },
  mixins: [tableListMixin],
  created() {
    this.getSearchConfig()
  },
  methods: {
    handleAdd() {
      this.$router.push('./add')
    },
    handDownload() {
      fileDownload('/api2/api/educational-routine/art/apply/download', {...this.search}, { name: '艺体生转入转入申请', show: true })
    },
    handleClick(row) {
      this.$router.push('./detail?id=' + row.id)
    },
    getData() {
      this.getDataApi('/api2/api/educational-routine/art/apply/get-list')
    },
    getSearchConfig() {
      get_school2().then(res => {
        this.searchConfig[3].options = res.map(item => ({ label: item.school_name, value: item.id}))
      })
      get_grade().then(res => {
        this.searchConfig[4].options = res.map(item => ({ label: item.grade_name, value: item.id}))
      })
      get_major().then(res => {
        this.list = res
        this.getMajorList(0)
      })
    },
    getClassList(keyword, type, idx) {
      if(!keyword) {
        this.searchConfig[idx].options = []
      } else {
        const { school_id, grade_id } = this.search
        const data = { keyword, type }
        if(school_id) {
          data.school_id = school_id
        }
        if(grade_id) {
          data.grade_id = grade_id
        }
        as2_search_class(data).then(res => {
          this.searchConfig[idx].options = res.map(item => ({ label: item.class_name, value: item.id}))
        })
      }
    },
    getMajorList(type) {
      const arr = []
      const len = this.list.length

      for (let i = 0; i < len; i++) {
        if(type === 1 && i === 0) continue
        if(type === 2 && i === 1) continue
        const item = this.list[i].major;
        item.map(child => {
          arr.push({ label: child.name, value: child.id})
        })
      }
      this.searchConfig[2].options = arr
    },
    onSearchChange(search) {
      this.searchConfig[5].options = []
      this.searchConfig[6].options = []
      this.searchConfig[7].options = []
      if(search.class_id) {
        search.class_id = ''
      }
      if(search.after_class_id) {
        search.after_class_id = ''
      }
      if(search.now_class_id) {
        search.now_class_id = ''
      }
    },
    onReset() {
      this.search = {}
      this.searchConfig[5].options = []
      this.searchConfig[6].options = []
      this.searchConfig[7].options = []
      this.getMajorList(0)
    },
    handleUpload() {
      this.visible = true
    },
    handleSelectFile(type) {
      handleChooseFile({
        api: '/api2/api/educational-routine/art/apply/import',
        data: { type },
        show: true,
        onSuccess: (res) => {
          this.$message.success('上传成功')
          this.visible = false
        }
      })
    },
    handleDownFile(type) {
      const params = { name: `艺体生${type === 1 ? '转入' : '转出'}申请模板`, type: 'xlsx' }
      const api = `${process.env.VUE_APP_URL_OSS}preview/art-sport-student-template-${type === 1 ? 'in' : 'out'}.xlsx`
      fileDownload(api, {}, params).then(res => {
        this.visible = false
      })
    }
  },
  data() {
    return {
      visible: false,
      list: [],
      searchConfig: [
        {
          tag: 'input',
          placeholder: '请输入学生姓名',
          prop: 'keyword'
        },
        {
          tag: 'select',
          placeholder: '请选择转入/转出',
          prop: 'type',
          options: [
            { label: '转入', value: 1 },
            { label: '转出', value: 2 }
          ],
          change:(val, search) => {
            this.getMajorList(val)
            if(search.major) {
              search.major = ''
            }
          }
        },
        {
          tag: 'select',
          placeholder: '请选择专业方向',
          prop: 'major',
          options: []
        },
        {
          tag: 'select',
          placeholder: '请选择校区',
          prop: 'school_id',
          options: [],
          change: (val, search) => {
            this.onSearchChange(search)
          }
        },
        {
          tag: 'select',
          placeholder: '请选择年级',
          prop: 'grade_id',
          options: [],
          change: (val, search) => {
            this.onSearchChange(search)
          }
        },
        {
          tag: 'select',
          placeholder: '请输入行政班',
          prop: 'class_id',
          reserveKeyword: true,
          filterable: true,
          remote: true,
          remoteMethod: (key) => {
            this.getClassList(key, 0, 5)
          },
          options: []
        },
        {
          tag: 'select',
          placeholder: '请输入转入行政班',
          prop: 'after_class_id',
          reserveKeyword: true,
          filterable: true,
          remote: true,
          remoteMethod: (key) => {
            this.getClassList(key, 0, 6)
          },
          options: []
        },
        {
          tag: 'select',
          placeholder: '请输入当前行政班',
          prop: 'now_class_id',
          reserveKeyword: true,
          filterable: true,
          remote: true,
          remoteMethod: (key) => {
            this.getClassList(key, 1, 7)
          },
          options: []
        },
        {
          tag: 'select',
          placeholder: '请选择审批结果',
          prop: 'status',
          options: [
            { label: '审核中', value: 0 },
            { label: '通过', value: 1 },
            { label: '驳回', value: 2 },
						{label: '撤销', value: 3},
						{label: '失败', value: 4}
          ]
        },
      ],
      tableConfig: [
        {
          prop: 'student',
          label: '学生姓名',
        },
        {
          prop: 'student_no',
          label: '学号',
        },
        {
          prop: 'type',
          label: '艺体生转入/转出',
        },
        {
          prop: 'school',
          label: '校区',
        },
        {
          prop: 'grade',
          label: '年级',
        },
        {
          prop: 'class_name',
          label: '行政班',
        },
        {
          prop: 'after_class',
          label: '转入行政班',
        },
        {
          prop: 'now_class',
          label: '当前行政班',
        },
        {
          prop: 'major',
          label: '转入专业方向',
        },
        {
          prop: 'art_at',
          label: '转入/转出艺体时间',
        },
        {
          prop: 'status_text',
          label: '审批结果',
        },
        {
          label: '操作',
          width: '100rem',
          handle: true,
          fixed: 'right',
          buttons: [{ type:"view", text:"查看" }]
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped></style>
